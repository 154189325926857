html,
body {
  background-color: #f6f6f6;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.main-container {
  margin: 0 auto;
  max-width: 2000px;
}

.push {
  margin-left: auto;
  padding: 20px;
}

/* Center Section */

.parentblock {
  display: flex;
  justify-content: center;
}

.twoblocks {
  margin-top: 10vh;
  background: linear-gradient(to right, #9bf1ae 50%, #2088e8 50%);
  height: 1vh;
  width: 13vw;
}

.light-scrap {
  display: flex;
  justify-content: center;
}

.lightbulb {
  width: 100px;
  height: 100px;

  position: relative;
}

.scrapbook {
  width: 100px;
  height: 100px;

  position: relative;
}

.community {
  width: 150px;
  height: 100px;

  position: relative;
}
.one-description {
  display: flex;
  justify-content: center;
}

.description {
  font-size: 25px;
  font-family: "Mukta Mahee", sans-serif;
  color: #3e4e55;
  font-weight: 800;
  margin-bottom: 14vh;
}

.one-liner {
  color: #007ea1;
}

/* @media screen and (max-width:1200px) {
  .scrapbook {
    grid-column: 55/60;
  }
} */

@media screen and (max-width: 990px) {
  .description {
    text-align: center;
  }
  .lightbulb {
    width: 60px;
    height: 60px;
  }
  .scrapbook {
    width: 60px;
    height: 60px;
  }
}

.three-icons {
  display: flex;
  margin-top: 8vh;
  justify-content: space-around;
  font-size: 20px;
}

.three-headers {
  color: #007ea1;
  font-weight: 800;
  font-size: 20px;
  font-family: "Mukta Mahee", sans-serif;
}

.icon-descriptions {
  display: flex;
  justify-content: space-around;
  text-align: center;
  font-family: "Mukta Mahee", sans-serif;
  margin-top: 2vh;
}

.three-descriptions {
  width: 90%;
  color: #3e4e55;
  font-family: "Mukta Mahee", sans-serif;
}

.three-images {
  display: flex;
  margin-top: 8vh;
  justify-content: space-around;
}

.three-big-headers {
  color: #007ea1;
  font-weight: 800;
  font-size: 40px;
  font-family: "Mukta Mahee", sans-serif;
}

.three-image-descriptions {
  width: 70%;
  color: #3e4e55;
  font-size: 30px;
  font-family: "Mukta Mahee", sans-serif;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.welcome-line {
  font-size: 3vh;
  text-align: center;
}

.website-name {
  font-weight: bolder;
  color: #3477eb;
}

.welcome-logo {
  font-size: 3em;
  color: #d4f1f3;

  padding: 7vh 7vh 7vh 0px;
}

/* Navigation Bar */
.main-nav {
  display: flex;
  list-style: none;
  font-size: 1em;
  font-weight: 700;
  margin: 0;
  height: 4em;
  align-items: center;
  font-family: "Mukta Mahee", sans-serif;
}

.logo {
  font-size: 3em;
  color: #ffffff;
  padding: 7vh 7vh 7vh 0px;
}

li {
  padding: 7vh;
}

.top {
  background-color: #3e4e55;
}

/* Footer */

.footer {
  margin-top: 15vh;
  min-height: 15vh;
  background-color: #3e4e55;
  color: white;
  font-size: 20px;
}

.footer-logo {
  font-size: 30px;
  margin-left: 10px;
  vertical-align: middle;
}

.social-media-icons {
  margin: 0 10px 0 10px;
}
