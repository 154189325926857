html,
body {
  background-color: #e0dede;
  height: 100%;
  margin: 0px;
  min-height: 100%;
  overflow-x: hidden;
}

.bg {
  margin: 0 auto;
}
